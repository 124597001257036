<template>
  <div>
    <figure
      v-if="
        ['Guilty Masters by In Beauty Academy 2024'].includes(ticket.event_name)
      "
      class="ticket-header"
    >
      <img src="@/assets/header-1403.png" :alt="ticket.event_name" />
    </figure>
    <figure
      v-else-if="
        ['Formação de Estética by In Beauty Academy 2024'].includes(
          ticket.event_name
        )
      "
      class="ticket-header"
    >
      <img src="@/assets/header-1404.png" :alt="ticket.event_name" />
    </figure>
    <figure
      v-else-if="
        ['Formação de Cabelo by In Beauty Academy 2024'].includes(
          ticket.event_name
        )
      "
      class="ticket-header"
    >
      <img src="@/assets/header-1402.png" :alt="ticket.event_name" />
    </figure>
    <figure
      v-else-if="
        ['INTERNATIONAL HAIR SHOW by Expocosmética & Academy in Motion 2025'].includes(
          ticket.event_name
        )
      "
      class="ticket-header"
    >
      <img src="@/assets/header-2837.png" :alt="ticket.event_name" />
    </figure>
    <figure
      v-if="
        ['Guilty Masters by Expocosmética 2025'].includes(ticket.event_name)
      "
      class="ticket-header"
    >
      <img src="@/assets/header-3025.png" :alt="ticket.event_name" />
    </figure>
    <figure
      v-if="
        ['Beauty School by Expocosmética 2025'].includes(ticket.event_name)
      "
      class="ticket-header"
    >
      <img src="@/assets/header-3154.png" :alt="ticket.event_name" />
    </figure>       
    <div class="ticket-container">
      <h4 v-if="!loading && ticket.ticket_code">{{ ticket.event_name }}</h4>
      <div class="lds-default" v-if="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <QRCodeVue3
        :width="256"
        :height="256"
        :value="ticket.ticket_code"
        :key="ticket.ticket_code"
        :dotsOptions="{
          type: 'squares',
        }"
        :margin="20"
        :cornersSquareOptions="{ type: 'square' }"
        :cornersDotOptions="{ type: 'square' }"
        :qr-options="{
          errorCorrectionLevel: 'H',
        }"
        :imageOptions="{
          margin: 4,
        }"
        :image="image"
        imgclass="qr-code-container"
        v-if="!loading"
      />
      <p class="qrcode-ticket-title" v-if="!loading && ticket.ticket_code">
        <span v-if="ticket.event_name == 'Reunião Vodafone 2024'"
          >Apresente este QR code nos pontos de credenciação. Este código é
          individual e intransmissível.</span
        >
        <span v-else>{{ ticket.ticket_type_name }}</span>
      </p>
      <p v-if="!ticket.is_timeframe_usable && !loading && ticket.ticket_code">
        <span>This ticket will be valid starting</span>
        {{ formatDate(ticket.usage_start_date) }}
      </p>
      <div
        v-if="!loading && !ticket.ticket_code"
        ng-if="!controller.loading && !controller.ticket_type"
        style="margin-top: 55px"
      >
        <span
          class="material-symbols-outlined"
          style="font-size: 72px; margin-bottom: 30px"
        >
          block
        </span>
        <h5>Ticket code is invalid.</h5>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import router from "@/router";
import { ticketQrcodeCheck } from "@/api/tickets";
import { ITicket } from "@/models/ticket";
import { ref } from "vue";
import QRCodeVue3 from "qrcode-vue3";
import "@fontsource/roboto";
import moment from "moment";
import { beamianLogo, vodafoneLogo, ps_logo } from "@/utils/logos";

const ticketCode = router.currentRoute.value.params.ticket_code as string;
const ticket = ref({} as ITicket);
const loading = ref(true as boolean);
const image = ref(beamianLogo);

const vodafoneEventNames = [
  "PRIMAVERA SOUND PORTO 2024",
  "Reunião Vodafone 2024",
  "VODAFONE PAREDES DE COURA 2024",
];

const formatDate = (value: string): string => {
  if (value) {
    return moment(String(value)).format("DD/MM h:mm a");
  }
  return value;
};

ticketQrcodeCheck(ticketCode)
  .then((response) => {
    loading.value = false;
    ticket.value = response.data;
    console.log(ticket.value)
    // Case for PRIMAVERA SOUND PORTO 2024
    if (vodafoneEventNames.includes(ticket.value.event_name)) {
      image.value = vodafoneLogo;
    }
    
    // Case for Centenário de Mário Soares event (2771)
    if(ticket.value.event_name === 'Centenário de Mário Soares') {
      image.value = ps_logo;
    }
  })
  .catch(() => {
    loading.value = false;
  });
</script>

<style lang="scss">
.qr-code-container {
  border: {
    radius: 13px;
  }
}

h4 {
  font-size: 20px;
  line-height: 1.1;
  margin: 11px 0;
}

h5 {
  font-size: 16px;
}

.qrcode-ticket-title {
  font-family: "Roboto";
  font-size: 18px;
  color: #303030;
  margin: 11px 0;
  line-height: 1.1;
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-top: 55px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #3e5460;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

.ticket-header {
  img {
    width: 100%;
    max-width: 375px;
    height: auto;
  }
}

.ticket-container {
  padding: 30px;
}
</style>
